.person-search-form {
  position: relative;
}

.person-search-form input {
  margin: 0;
  z-index: 10;
  position: relative;
  z-index: 10;
  width: 100%;
}

.person-search-results {
  position: absolute;
  padding: 0;
  margin: 0;
  list-style: none;
  background: #fff;
  border: 1px solid #686868;
  width: 100%;
  margin-top: -5px;
  padding-top: 5px;
}

.person-search-results li {
  padding: 0;
  margin: 0;
}

.person-search-results li button {
  border: none;
  background: none;
  border-radius: 0;
  padding: 10px;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 0.8em;
}

.person-search-results li button:hover {
  background: #005700;
  color: #fff;
}

.person-search-results li button.add-new {
  font-weight: 600;
}

.person-search-results li:not(:last-child) button {
  border-bottom: 1px solid #aaaaaa;
}
