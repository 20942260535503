.tutorial-step {
  width: 0;
  height: 0;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 0.835em;
  text-transform: none;
  z-index: 1;
  pointer-events: none;
}

.tutorial-window {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  border-radius: 7px;
  color: #1b1b1b;
  pointer-events: all;
}

.tutorial-step.align-right .tutorial-window {
  -webkit-transform: translateX(-88%);
  transform: translateX(-88%);
}

.tutorial-step.align-left .tutorial-window {
  -webkit-transform: translateX(-12%);
  transform: translateX(-12%);
}

.tutorial-window-title {
  background: #5fb751;
  color: #fff;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 7px 20px;
  position: relative;
}

.tutorial-window-close {
  background: none;
  border: none;
  padding: 0;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (min-width: 801px) {
  .tutorial-window-close {
    display: none;
  }
}

.tutorial-window-content {
  padding: 20px;
  background-color: #fff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.tutorial-step.with-arrow .tutorial-window-content::after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  width: 20px;
  height: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  z-index: -1;
}

.tutorial-window-content p:first-child {
  margin-top: 0;
}

.tutorial-window-content p:last-child {
  margin-bottom: 0;
}

.tutorial-window-content strong {
  color: #5fb751;
}

.tutorial-window-content strong.red {
  color: #ce3939;
}

.tutorial-step.align-right .tutorial-window-content::after {
  left: 88%;
}

.tutorial-step.align-left .tutorial-window-content::after {
  left: 12%;
}

@media screen and (max-width: 800px) {
  .tutorial-step {
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    height: 100%;
  }

  .tutorial-window {
    left: 50% !important;
    top: 50% !important;
    bottom: auto !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }

  .tutorial-window-content::after {
    display: none !important;
  }
}
