.family-tree {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.family-tree-cluster-row {
  white-space: nowrap;
  display: inline-block;
}

.family-tree-cluster-row * {
  white-space: normal;
}

.family-tree:after {
  content: "";
  display: table;
  clear: both;
}
