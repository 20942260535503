.person-field,
.add-person-button {
  border: 1px solid rgb(27, 27, 27, 0.45);
  border-radius: 7px;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  font-family: "Cambria", serif;
  width: 100%;
  margin: 10px 0;
  overflow: hidden;
}

.person-field.has-children {
  margin: 20px 0;
}

.person-field {
  background: #fff;
  position: relative;
}

.person-field-children {
  padding: 0 12px 14px 12px;
}

.person-field-button {
  text-align: left;
  background-color: transparent;
  border: none;
  display: block;
  width: 100%;
  padding: 12px 14px;
  padding-right: 36px;
}

.person-field-button:hover,
.person-field-button:hover + .person-field-delete + .person-field-children,
.person-field-button:hover + .person-field-children {
  background-color: #fafafa;
}

.person-field.has-children .person-field-button:active,
.person-field.has-children
  .person-field-button:active
  + .person-field-delete
  + .person-field-children,
.person-field.has-children .person-field-button:active + .person-field-children {
  background-color: #f4f4f4;
}

.person-field-title {
  font-size: 0.7em;
  text-transform: uppercase;
}

.person-field-name {
  border: none;
  background: none;
  font-size: 1.1em;
  font-family: "Cambria", serif;
  padding: 0;
}

.person-field-delete {
  position: absolute;
  right: 14px;
  top: 24px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background: none;
  text-align: center;
  font-size: 0;
}

.person-field-delete img {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  opacity: 0.35;
}

.person-field-delete:hover img {
  opacity: 0.75;
}

.add-person-button {
  background: #ededed;
  text-transform: uppercase;
  color: #333;
  padding: 12px 14px;
  font-size: 1.2em;
}

.add-person-button::before {
  content: "+";
  opacity: 0.4;
  margin-right: 0.3em;
}

.add-person-button:hover {
  background: #e3e3e3;
  color: #000;
}

.add-person-button:active,
.person-field:not(.has-children):active {
  -webkit-transform: translate(1px, 1px);
  transform: translate(1px, 1px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1440px) {
  .person-field-delete {
    top: 22px;
  }
}
