.dna-analysis-results {
  overflow: auto;
  max-height: calc(100% - 63px);
}

.dna-analysis-results table {
  width: 100%;
  border-collapse: collapse;
}

.dna-analysis-results span.name {
  color: #5fb751;
  font-weight: 600;
  font-style: italic;
}

.dna-analysis-results span.relationship {
  font-style: italic;
  font-weight: 600;
}

.dna-analysis-results .dna-analysis-result.highlighted {
  background: #e1ffdc;
}

.dna-analysis-results .dna-analysis-result:nth-child(2n) {
  background: #f9f9ff;
}

.dna-analysis-results th,
.dna-analysis-results td {
  text-align: left;
  border-right: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  padding: 10px;
}

.dna-analysis-results th:last-child,
.dna-analysis-results td:last-child {
  border-right: none;
}

.dna-analysis-results .id {
  width: 50px;
}
.dna-analysis-results .strength {
  width: 150px;
}
.dna-analysis-results .outliers {
  width: 100px;
}

@media screen and (min-width: 801px) {
  .dna-analysis-results td.outliers {
    background: #ffd6d6;
  }
  .dna-analysis-results .dna-analysis-result.highlighted td.outliers,
  .dna-analysis-results .dna-analysis-result:nth-child(2n) td.outliers {
    background: #f1c8c8;
  }
}

.dna-analysis-results-message {
  padding: 20px;
  color: #777;
}

@media screen and (max-width: 800px) {
  .dna-analysis-results .id {
    width: 10px;
  }
  .dna-analysis-results td.outliers {
    border-bottom: none;
  }

  .dna-analysis-result > td {
    border-bottom-width: 3px;
  }
}
