.family-tree-node {
  width: 75px;
  height: 90px;
  background: #fff;
  border: 1px solid #686868;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 0.8em;
  padding-bottom: 20px;
  transition: 0.3s ease;
  transition-property: opacity;
}

.family-tree-node.selected {
  z-index: 1;
}

.family-tree-cluster.active .family-tree-node.selected {
  border: 5px solid #36772b !important;
  background: #d1ffca;
}

.family-tree-node .expand-handle {
  position: absolute;
  right: 100%;
  bottom: 10%;
  border: 1px solid #686868;
  padding: 2px;
}

.family-tree-node-shared-dna {
  background: #d1ffca;
  position: absolute;
  height: 20px;
  width: 100%;
  bottom: 0;
  left: 0;
  font-weight: 600;
  border-top: 1px solid #686868;
}
