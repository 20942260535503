.dna-analysis-result-details {
  position: relative;
}

.dna-analysis-result-details table {
  table-layout: fixed;
}

.dna-analysis-result-details button.expand-result {
  background: none;
  border: none;
  width: 15px;
  height: 15px;
  text-align: center;
  padding: 0;
  position: absolute;
  top: 5px;
  opacity: 0.3;
  font-size: 0;
}

.dna-analysis-result-details button.expand-result:hover {
  opacity: 0.5;
}

.dna-analysis-result-details button.expand-result image {
  width: 15px;
  height: 15px;
}

.dna-analysis-result-details > span.description {
  margin-left: 25px;
  display: inline-block;
}

.dna-analysis-result-details ul.insights {
  padding: 0;
  margin-left: 30px;
}
.dna-analysis-result-details li {
  list-style: none;
  margin: 10px 0;
  padding: 5px 10px;
  position: relative;
}

.dna-analysis-result-details li {
  font-size: 0.88em;
}

.dna-analysis-result-details li.evidence,
.dna-analysis-result-details li.evidence span.name {
  color: #5fb751;
}

.dna-analysis-result-details li.outlier,
.dna-analysis-result-details li.outlier span.name {
  color: #ce3939;
}

.dna-analysis-result-details .hierarchy-line-horizontal,
.dna-analysis-result-details .hierarchy-line-vertical {
  background: #000;
  position: absolute;
  opacity: 0.2;
}

.dna-analysis-result-details .hierarchy-line-horizontal {
  height: 1px;
  width: 22px;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.dna-analysis-result-details .hierarchy-line-vertical {
  width: 1px;
  left: 8px;
  top: 20px;
}
