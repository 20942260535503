.dna-analysis-result-strength {
  height: 14px;
  width: calc(100% - 35px);
  display: inline-block;
  background: #ccc;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #b0b0b0;
}

.dna-analysis-result-strength-meter {
  position: absolute;
  height: 100%;
  background: #5fb751;
}

@media screen and (max-width: 800px) {
  .dna-analysis-result-strength {
    width: 100px;
  }
}

@media screen and (max-width: 300px) {
  .dna-analysis-result-strength {
    display: none;
  }
}
