.edit-person-window {
  position: absolute;
  top: 0;
  height: 100%;
  width: 30%;
  background-color: #f6f6ff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  overflow: auto;
  transition: left 0.2s ease;
  z-index: 1;
}

.edit-person-window-close {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  width: 20px;
  height: 20px;
}

.edit-person-window-close img {
  width: 20px;
  height: 20px;
  opacity: 0.35;
}

.edit-person-window-close:hover img {
  opacity: 0.75;
}

.edit-person-window section {
  padding: 2em;
}
.edit-person-window section:not(:last-child) {
  border-bottom: 1px solid rgb(27, 27, 27, 0.35);
}

.edit-person-window h2 {
  position: relative;
  margin-bottom: 0;
}

.edit-person-window .person-id {
  opacity: 0.35;
  color: #000;
  margin-top: 0;
}

.edit-person-delete {
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background: none;
  text-align: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 0.5em;
}

.edit-person-delete img {
  width: 20px;
  height: 20px;
  vertical-align: top;
  opacity: 0.35;
}

.edit-person-delete:hover img {
  opacity: 0.75;
}

@media screen and (max-width: 1024px) {
  .edit-person-window {
    width: 100%;
    max-width: 575px;
  }
}

@media screen and (max-width: 480px) {
  .edit-person-window section {
    padding: 1.5em;
  }
}
