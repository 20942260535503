header.top-bar {
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  height: 80px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.top-bar-logo {
  height: 45px;
  left: 30px;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main-menu {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main-menu button:not(.button):hover {
  text-decoration: underline;
}

.main-menu button {
  margin: 0 15px;
}

.main-menu button:last-child {
  margin-right: 0;
}

.main-menu .mobile-menu-close {
  display: none;
}

header .burger {
  display: none;
}

@media screen and (max-width: 900px) {
  .top-bar-logo {
    left: 20px;
    height: 40px;
  }
  .main-menu {
    right: 10px;
  }
  .main-menu button {
    margin: 0 10px;
  }
}

@media screen and (max-width: 800px) {
  header .burger {
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    width: 22px;
    height: 22px;
    right: 14px;
    top: 14px;
    display: block;
    opacity: 0.35;
  }

  header .burger:hover {
    opacity: 0.75;
  }

  header.top-bar {
    height: 50px;
  }

  .top-bar-logo {
    height: 30px;
    left: 10px;
  }

  .main-menu {
    position: fixed;
    overflow: auto;
    width: 100%;
    height: 100%;
    right: auto;
    transform: none;
    top: 0;
    left: 0;
    background: #fff;
    padding: 20px;
    padding-top: 35px;
    display: none;
    z-index: 1000;
  }

  .main-menu .mobile-menu-close {
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    width: 22px;
    height: 22px;
    right: 14px;
    top: 14px;
    display: block;
    opacity: 0.35;
  }

  .main-menu .mobile-menu-close:hover {
    opacity: 0.75;
  }

  .main-menu button {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .main-menu button.link-button {
    padding: 20px 0;
  }

  .main-menu button.link-button:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .main-menu .demo-button {
    margin-top: 10px;
  }
}
