@font-face {
  font-family: "Cambria";
  src: url("assets/fonts/Cambria.eot");
  src: url("assets/fonts/Cambria.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Cambria.woff2") format("woff2"),
    url("assets/fonts/Cambria.woff") format("woff"),
    url("assets/fonts/Cambria.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cambria";
  src: url("assets/fonts/Cambria-Bold.eot");
  src: url("assets/fonts/Cambria-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Cambria-Bold.woff2") format("woff2"),
    url("assets/fonts/Cambria-Bold.woff") format("woff"),
    url("assets/fonts/Cambria-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cambria";
  src: url("assets/fonts/Cambria-BoldItalic.eot");
  src: url("assets/fonts/Cambria-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Cambria-BoldItalic.woff2") format("woff2"),
    url("assets/fonts/Cambria-BoldItalic.woff") format("woff"),
    url("assets/fonts/Cambria-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Cambria";
  src: url("assets/fonts/Cambria-Italic.eot");
  src: url("assets/fonts/Cambria-Italic.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Cambria-Italic.woff2") format("woff2"),
    url("assets/fonts/Cambria-Italic.woff") format("woff"),
    url("assets/fonts/Cambria-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Cambria", serif;
  color: #1b1b1b;
  font-size: 20px;
}

.App {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

main {
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  top: 80px;
  left: 0;
  background: #dddddd;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 2.4em;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.4em;
}

input[type="text"],
input[type="email"] {
  border: 1px solid rgb(27, 27, 27, 0.45);
  border-radius: 7px;
  padding: 12px 14px;
  box-sizing: border-box;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  font-size: 1.1em;
  font-family: "Cambria", serif;
}

button {
  cursor: pointer;
  font-family: "Cambria", serif;
  font-size: 1em;
}

button.button {
  background: #5fb751;
  border: none;
  border-radius: 10px;
  padding: 0.4em 0.8em;
  color: #fff;
  font-size: 1.2em;
  text-transform: uppercase;
}

button.button:hover {
  background: #5fc550;
}

button.button.light {
  background: #fff;
  color: #1b1b1b;
}

button.button.light:hover {
  background: #ccc;
}

button.ghost-button,
.modal button:nth-of-type(2) {
  background: none;
  color: #1b1b1b;
  border: 2px solid #1b1b1b;
  box-sizing: border-box;
  padding: 8px 18px;
}

button.ghost-button:hover {
  background: rgba(255, 255, 255, 0.5);
}

button.link-button {
  border: none;
  background: none;
  font-size: 1em;
  text-transform: uppercase;
  padding: 0;
  color: #1b1b1b;
  border-radius: 0;
  opacity: 0.65;
}

button.link-button:hover {
  background: none;
  opacity: 1;
}

button.button.disabled {
  pointer-events: none;
}

@media screen and (max-width: 1440px) {
  body {
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  main {
    height: calc(100% - 50px);
    top: 50px;
  }
}

/****MODAL****/

.dim {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.2;
  left: 0;
  top: 0;
  z-index: 999;
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border: 1px solid rgb(27, 27, 27, 0.45);
  border-radius: 7px;
  z-index: 1000;
  max-width: 100%;
}

.modal h2,
.modal h3,
.modal h4 {
  margin-bottom: 0;
}

.modal button:nth-of-type(2):hover {
  background: rgba(0, 0, 0, 0.2);
}

.modal form {
  margin: 15px 0;
}

.modal button:nth-of-type(2) {
  margin-left: 10px;
}

@media screen and (max-width: 600px) {
  .modal {
    width: 100%;
  }
}
