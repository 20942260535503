.modal.loading {
  z-index: 1002;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dim.loading {
  z-index: 1001;
}

.modal.loading h2 {
  margin-left: 30px;
}

.modal.loading img {
  position: absolute;
  width: 30px;
  top: 35px;
  left: 20px;
}
