.family-tree-lines {
  position: absolute;
  left: -20px;
  top: -20px;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  transition: 0.3s ease;
  transition-property: opacity;
  opacity: 1;
}

.family-tree-lines line {
  stroke-width: 2px;
}

.family-tree-lines .union-1,
.family-tree-lines .union-6,
.family-tree-lines .union-11 {
  stroke: #2b2b2b;
  fill: #2b2b2b;
}
.family-tree-lines .union-2,
.family-tree-lines .union-7,
.family-tree-lines .union-12 {
  stroke: #a70505;
  fill: #a70505;
}
.family-tree-lines .union-3,
.family-tree-lines .union-8,
.family-tree-lines .union-13 {
  stroke: #1cb308;
  fill: #1cb308;
}
.family-tree-lines .union-4,
.family-tree-lines .union-9,
.family-tree-lines .union-14 {
  stroke: #b39908;
  fill: #b39908;
}
.family-tree-lines .union-5,
.family-tree-lines .union-10,
.family-tree-lines .union-15 {
  stroke: #0b08b3;
  fill: #0b08b3;
}
