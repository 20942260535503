.welcome-message {
  text-align: center;
  overflow: auto;
  padding: 50px 10vw;
  max-height: 100%;
  box-sizing: border-box;
  width: 100%;
}

@media screen and (min-width: 1025px) and (min-height: 701px) {
  .welcome-message {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: calc(1166px + 20vw);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.welcome-buttons {
  margin-top: 40px;
}

.welcome-buttons button {
  margin: 10px;
}

.welcome-message strong {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.welcome-message .step-tag {
  padding: 2px 7px;
  border: 2px solid #1b1b1b;
  color: #1b1b1b;
  border-radius: 10px;
  margin-right: 10px;
  font-size: 18px;
  position: relative;
  top: -1px;
}

.welcome-panel-steps div {
  padding: 10px 0;
}

@media screen and (min-width: 1025px) {
  .welcome-panel-steps {
    display: table;
  }

  .welcome-panel-steps div {
    display: table-cell;
    width: 50%;
    padding: 10px 30px;
  }

  .welcome-panel-steps div:first-child {
    border-right: 1px solid #aaa;
  }
}

@media screen and (max-width: 480px) {
  .welcome-buttons button {
    width: 100%;
    margin: 10px 0;
  }
}
