.family-tree-window {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

.react-transform-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: visible !important;
}

.family-tree-container {
  min-width: 100vw;
  min-height: 100vh;
  cursor: grab;
}

.family-tree-container.dragging {
  cursor: grabbing;
}

/****TREE GUI****/

.tree-gui {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.tree-gui > button {
  width: 50px;
  height: 50px;
  margin: 15px;
  display: block;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  position: relative;
  background-color: #5fb751;
  padding: 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.tree-gui > button:hover {
  background-color: #5fc550;
}

.tree-gui > button > img {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.tree-gui > button.search-button {
  background: #fff;
}

.tree-gui > button.search-button > img {
  opacity: 0.7;
}

.tree-gui > button.search-button:hover {
  background: #eee;
}

@media screen and (max-width: 1024px) {
  .tree-gui {
    bottom: 60px;
    right: 0;
  }
}
