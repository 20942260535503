.dna-analysis-panel {
  position: absolute;
  width: calc(100% - 240px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #fff;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  height: 600px;
  max-height: calc(100% - 100px);
  transition: 0.1s ease bottom;
  bottom: -100%;
}

.dna-analysis-panel-header {
  background: #f6f6ff;
  box-sizing: border-box;
  padding: 15px;
  padding-right: 9em;
  padding-left: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #c1c1c8;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
}

.dna-analysis-panel-header button.expand-panel {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 0;
}

.dna-analysis-panel-header button.expand-panel img {
  width: 15px;
  height: 15px;
  opacity: 0.6;
  vertical-align: middle;
}

.dna-analysis-panel-header button.expand-panel:hover img {
  opacity: 1;
}

.dna-analysis-panel.expanded .dna-analysis-panel-header button.expand-panel img {
  transform: rotate(180deg);
}

.dna-analysis-panel-header h3 {
  margin-bottom: 0;
  margin-top: 0;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#dna-analysis-panel-analyze-button {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 6em;
  text-align: center;
  height: 2em;
}

#dna-analysis-panel-analyze-button img {
  width: 25px;
  height: 25px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media screen and (min-width: 1025px) {
  .edit-person-window + .dna-analysis-panel {
    width: calc(100% - 30% - 120px);
    left: calc(50% + 15% - 40px);
    transition: 0.2s ease;
  }
}

@media screen and (max-width: 1024px) {
  .dna-analysis-panel {
    left: 50%;
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 800px) {
  .dna-analysis-panel {
    width: 100%;
    border-radius: 0 !important;
    max-height: 100%;
  }

  .dna-analysis-panel-header {
    border-radius: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .dna-analysis-panel-header {
    padding-left: 24px;
  }

  .dna-analysis-panel-header h3 {
    font-size: 1.3em;
  }
  .dna-analysis-panel-header button.expand-panel {
    left: 5px;
    width: 15px;
    height: 15px;
  }

  .dna-analysis-panel-header button.expand-panel img {
    width: 10px;
    height: 10px;
  }
}
